.pageName {
  min-width: 200px;
}

.main {
  min-width: 700px;
  margin-top: 60px;
}

.column {
  float: left;
  width: 33%;
  margin-bottom: 40px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}
