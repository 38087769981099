.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 400px;
}

.input {
  background-color: white;
  min-width: 500px !important;
  font-size: 14px !important;
  border-radius: 4px;
  color: #000000 !important;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.addMore {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
}
