.chart {
  width: 893px;
  height: 308px;
  background: #ffffff;
}

.body {
  margin-top: 50px;
  background: #ffffff;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.btn {
  font-weight: bolder;
  height: 20px;
}

.btnHidden {
  font-weight: bolder;
  visibility: hidden;
  height: 20px;
}

.graphHeader {
  margin-left: 26px;
  margin-top: 8px;
  display: flex;
}

.headerBranch {
  float: left;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}

.headerDate {
  font-weight: bold;
}

.headerTitle {
  font-weight: 500;
}

.headerCentral {
  flex-grow: 1;
  color: #4d4d4d;
  font-size: 13px;
}
