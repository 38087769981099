@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);
body {
  margin: 0;
  font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
}

code {
  font-family: "Source Sans Pro", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Login_root__2sTXf {
  height: 100vh;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Login_loginDiv__2UlTk {
  width: 507px;
  min-height: 387px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 17px #2c28281c;
  opacity: 1;
}

.Login_validate__1cwbE {
  width: 218px;
  height: 58px;
  font-weight: bold !important;
  font-size: 20px !important;
  text-transform: capitalize !important;
  margin-top: 26px !important;
}

.Login_logo__3YmU4 {
  margin-top: 10px;
}

.Login_mainHeader__1dBOQ {
  color: #4d4d4d;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 9px;
}

.Login_react-phone-number-input__country__3cuO6 {
  display: none;
}

.Login_subHeader__2mYwU {
  color: #4d4d4d;
  font-size: 15px;
  margin-bottom: 28px;
}

.Login_form__12aDo {
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.OtpInput_otpInputBox__e6b-u {
  width: 49px;
  height: 53px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 4px #1717172e;
  border: 1px solid #f6f6f6;
  border-radius: 5px;
  opacity: 0.8;
  display: flex;
  flex-direction: row;
  margin-right: 17px;
}

.OtpInput_otpInputBox__e6b-u:focus {
  border: 2px solid #4d4d4d;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}

.PhoneNumber_input__2QAho {
  background-color: white;
  width: 426px !important;
  height: 54px;
  font-size: 15px !important;
  box-shadow: 0px 2px 4px #1717172e;
  border: 1px solid #f6f6f6 !important;
  border-radius: 5px !important;
  opacity: 0.8 !important;
}

.PhoneNumber_phoneImg__2cwIW {
  margin-left: 3px;
  margin-right: 28px;
}

.ContentPage_header__2NIbg {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.ContentPage_container__2zsfe {
  margin-left: 53px;
  margin-top: 24px;
  display: flex;
}

.ContentPage_rightSection__1JMak {
  margin-left: 40px;
  margin-top: 13px;
}

.BranchSelector_root__1nSdS {
  background-color: #4d4d4d;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.BranchSelector_select__3rLsB {
  margin-left: 360px;
  right: 13%;
}

.DashboardForAdmin_pageName__29eKF {
  min-width: 200px;
}

.DataTableWithDates_container__INMfm {
  background-color: #ffffff;
  padding: 7px 18px;
  margin-top: 13px;
  margin-bottom: 26px;
  min-width: 928px;
}

.DataTableWithDates_header__2TOxE {
  min-height: 34px;
  font-size: 15px;
  font-weight: bold !important;
  background-color: #f9f9f9;
}

.DataTableWithDates_row__ri1AR {
  height: 51px !important;
}

.DataTableWithDates_cell__AFf3b {
  padding: 7px !important;
  line-height: 37px !important;
}

.DataTableWithDates_fromTo__23y3L {
  float: left;
  border: 1px;
}

.DataTableWithDates_centerAlign__3EJZJ {
  text-align: center !important;
}

.DataTableWithDates_pendingButton__3dyg0 {
  float: right;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  height: 24px;
  background-color: #4d4d4d !important;
  color: white !important;
}

.DataTableWithDates_widthDates__at3Rh {
  width: 94px !important;
  opacity: 0.8;
}

.DataTableWithDates_input__39rPb {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.DataTableWithDates_mainDate__10xCO {
  background-color: white;
  font-size: 12px;
  text-align: center;
}

.DataTableWithDates_searchIcon__3Dd0W {
  width: 12px;
  height: 12px;
}

.DataTableWithDates_loadingDiv__2_8Dz {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DataTableWithDates_loading__1ENGS {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DataTableWithDates_overflow__3eWsC {
  overflow: visible !important;
  height: unset !important;
}

.GlobalFilter_input__38baw {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 4px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.GlobalFilter_inline__1Sf2L {
  display: inline;
}

.GlobalFilter_searchRoot__28c70 {
  float: right;
  opacity: 0.8;
  margin-right: 8px !important;
  width: 132px !important;
}

.GlobalFilter_searchRoot__28c70 ::placeholder {
  color: #4d4d4d !important;
  font-weight: bolder;
  opacity: 0.8;
}

.GlobalFilter_widthSearch__1XggS {
  width: 132px !important;
}

.DatePickersForTableHeader_fromTo__1jPvj {
  float: left;
  border: 1px;
}

.DatePickersForTableHeader_widthDates__3SoWx {
  width: 94px !important;
  opacity: 0.8;
}

.DatePickersForTableHeader_input__ZEF5X {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.DatePickersForTableHeader_mainDate__1bJNL {
  background-color: white;
  font-size: 12px;
  text-align: center;
}

.DatePickersForTableHeader_clearButton__1WED- {
  margin-top: 10px !important;
  display: inline-block;
  font-size: 14px;
}

.DashboardTable_table__2ZfT6 {
  min-width: 892px !important;
  min-height: 316px !important;
}

.DashboardTable_red__dOGc4 {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.DashboardTable_green__1X3BV {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.DashboardTable_yellow__3BZAO {
  background-color: #ffca83 !important;
  text-transform: capitalize;
}

.MaterialConsumptionStatsModal_modal__kXHAZ {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 1120px;
  min-height: 662px;
}

.MaterialConsumptionStatsModal_modalRoot__299Ov {
  overflow: scroll;
  border-color: white;
}

.MaterialConsumptionStatsModal_modalTitleBox__2xEW7 {
  width: 1120px;
  height: 140px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.MaterialConsumptionStatsModal_modalTitleText__329Fa {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.MaterialConsumptionStatsModal_modalCloseIcon__2YquM {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
}

.MaterialConsumptionStatsModal_quantity__1Ubxh {
  font-size: medium;
}

.MaterialConsumptionStatsModal_date__3JlC8 {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.MaterialConsumptionStatsModal_branch__2HfyP {
  font-size: small;
}

.DataTable_container__1bd99 {
  background-color: #ffffff;
  padding: 20px 18px;
}

.DataTable_header__3le4t {
  min-height: 51px;
  font-size: 15px;
  font-weight: bold !important;
  background-color: #f9f9f9;
}

.DataTable_cell__1XfQT {
  padding: 0px !important;
}

.DataTable_loadingDiv__3tW-0 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.DataTable_centerAlign__14fV8 {
  text-align: center !important;
}

.DataTable_loading__gV7km {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ModalWithButton_button__2AiBC {
  width: 190px;
  height: 60px;
  border: 0px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 15px;
}

.ModalWithButton_button__2AiBC:hover {
  background-color: #fafafa;
  font-weight: 600;
}

.ModalWithButton_buttonTitle__2YxSP {
  margin-left: 9px;
  text-align: left;
}

.ModalWithButton_icon__19XD8 {
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.AddSalesModal_modal__1A5w_ {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 662px;
}

.AddSalesModal_input__3AYjW {
  background-color: white;
  width: 209px !important;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 15px;
  color: #4d4d4d !important;
}

.AddSalesModal_modalBody__1p3EC {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddSalesModal_inputOptions__2GPws {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.AddSalesModal_icon__2MR15 {
  right: 10% !important;
}

.AddSalesModal_autocompleteRoot__3y1B4 {
  margin-right: 10px;
}

.AddSalesModal_autocomplete__1-Jbk {
  width: 179px !important;
}

.AddSalesModal_rowNumber__2gp8e {
  margin-right: 10px;
}

.AddSalesModal_addMore__2gflF {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  left: 35%;
  position: relative;
}

.AddSalesModal_noDisplay__2OnMF {
  display: none;
}

.AddSalesModal_materialUnit__tP1p0 {
  width: 250px;
  margin-left: 10px !important;
}

.ModalPopup_modalTitleBox__22o-g {
  min-width: 400px;
  height: 95px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ModalPopup_modalTitleBoxWithBranch__3g80H {
  min-width: 400px;
  height: 110px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ModalPopup_modalTitleText__3TvD4 {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
  width: 100%;
}

.ModalPopup_modalCloseIcon__kjJk_ {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
}

.ModalPopup_modalButton__hVSJB {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #cecece;
  border-radius: 5px;
  opacity: 1;
  font: Bold 21px/21px Source Sans Pro;
  letter-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.ModalPopup_content__1Sp6V {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ModalPopup_date__3v0kk {
  padding-top: 23px;
  padding-left: 23px;
  float: left;
  border: 1px;
  z-index: 1;
}

.ModalPopup_widthDates__2yp3- {
  width: 100px !important;
  opacity: 0.8;
}

.ModalPopup_input__3kgoe {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 34px !important;
  font-weight: bolder;
}

.ModalPopup_branchName__1xbNF {
  font-size: medium;
}

.ModalPopup_download__2UezF {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  width: 100px;
}

.ModalPopup_downloadHidden__1C7gP {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  visibility: hidden;
}

.ModalPopup_upload__3dpW0 {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
}

.ModalPopup_uploadHidden__1ee9j {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  visibility: hidden;
}

.ModalPopup_buttons__P63Nn {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}

.EntryConfirmationDialog_body__Q-Njg {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.EntryConfirmationDialog_modalCloseIcon__3uCIE {
  color: #4d4d4d;
  margin-left: auto;
}

.EntryConfirmationDialog_iconButton__1dBjC {
  padding-bottom: 10px !important;
}

.EntryConfirmationDialog_modalTitleText__1483Z {
  text-align: center;
  font: bold 26px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  align-self: flex-start;
  margin-left: 15px;
  position: absolute;
  margin-top: 12px;
}

.EntryConfirmationDialog_button__2KAkG {
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  font: Bold 21px/21px Source Sans Pro !important;
  font-size: 15px !important;
  text-align: center;
  align-self: center;
  margin: 10px !important;
}

.EntryConfirmationDialog_borders__33Kpp {
  border-bottom: 1.5px solid black;
}

.AddRawOrClosingMaterialModal_modal__Rimfv {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 839px;
  min-height: 662px;
}

.AddRawOrClosingMaterialModal_input__3cAbp {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 12px !important;
  color: #4d4d4d !important;
}

.AddRawOrClosingMaterialModal_materialName__2_ZTz {
  width: 186px !important;
}

.AddRawOrClosingMaterialModal_materialUnit__TxhAf {
  width: 520px;
  margin-left: 10px !important;
}

.AddRawOrClosingMaterialModal_classification__UVmlL {
  width: 131px !important;
}

.AddRawOrClosingMaterialModal_modalBody__18m5T {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddRawOrClosingMaterialModal_inputOptions__3PsLt {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
  width: 752px;
}

.AddRawOrClosingMaterialModal_rowNumber__1hKDM {
  margin-right: 10px;
}

.AddRawOrClosingMaterialModal_icon__3LwuP {
  right: 10% !important;
}

.AddRawOrClosingMaterialModal_addMore__NlZke {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  left: 37%;
  position: relative;
}

.AddRawOrClosingMaterialModal_noDisplay__QqD-a {
  display: none;
}

.AddWastageModal_modal__3akWK {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 662px;
}

.AddWastageModal_input__1J_ip {
  background-color: white;
  width: 209px !important;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 15px;
  color: #4d4d4d !important;
}

.AddWastageModal_modalBody__w_wRm {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddWastageModal_inputOptions__2aD4a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.AddWastageModal_icon__3IcJJ {
  right: 10% !important;
}

.AddWastageModal_autocompleteRoot__2XXac {
  margin-right: 10px;
}

.AddWastageModal_autocomplete__3u4a_ {
  width: 179px !important;
}

.AddWastageModal_rowNumber__3dOrN {
  margin-right: 10px;
}

.AddWastageModal_addMore__2L4B_ {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  left: 35%;
  position: relative;
}

.AddWastageModal_noDisplay__3ZXUj {
  display: none;
}

.AddWastageModal_materialUnit__a84sJ {
  width: 250px;
  margin-left: 10px !important;
}

.ErrorBox_body__1QHWg {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.ErrorBox_error__38hND {
  width: auto;
  height: auto;
  justify-content: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 10px;
  border-block-start: 1.5px solid rgb(231, 230, 230);
}

.ErrorBox_modalCloseIcon__2WS7S {
  color: #4d4d4d;
  margin-left: auto;
}

.ErrorBox_iconButton__1ZxRp {
  padding-bottom: 10px !important;
}

.ErrorBox_errorText__2T9eG {
  font-size: 15px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-top: 22px;
  margin-left: 15px;
  margin-right: 15px;
}

.ErrorBox_modalTitleText__2ZtQg {
  text-align: center;
  font: bold 32px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  align-self: flex-start;
  margin-left: 15px;
  position: absolute;
  margin-top: 12px;
}

.ErrorBox_addButton__34cOn {
  width: 200px;
  height: 40px;
  background: #ed731d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #cecece;
  border-radius: 5px;
  opacity: 1;
  font: 16px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 20px;
}

.ErrorBox_borders__1yn4N {
  border-bottom: 1.5px solid black;
}

.AddStaffFoodModal_modal__1ouVr {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 839px;
  min-height: 662px;
}

.AddStaffFoodModal_input__3gspu {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 25px;
  color: #4d4d4d !important;
}

.AddStaffFoodModal_modalBody__1IBtA {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddStaffFoodModal_inputOptions__3lUJa {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

.AddStaffFoodModal_icon__3LSqK {
  right: 10% !important;
}

.AddStaffFoodModal_autocompleteRoot__9zWig {
  width: 40% !important;
}

.AddStaffFoodModal_rowNumber__1zYFP {
  margin-right: 10px;
}

.AddStaffFoodModal_autocomplete__29aKe {
  width: 10px !important;
}

.AddStaffFoodModal_addMore__ndeqR {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.AddStaffFoodModal_materialUnit___HEWF {
  width: 270px;
  margin-left: 20px !important;
}

.AddFavouritesModal_modal__3X2-z {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 839px;
  height: 662px;
  overflow-y: scroll;
}

.AddFavouritesModal_modalTitleBox__2vkjm {
  width: 839px;
  height: 110px;
  background: #f6f6f6 0% 0% no-repeat padding-box;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.AddFavouritesModal_modalTitleText__1rrrw {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.AddFavouritesModal_branchName__3ycKu {
  font-size: medium;
}

.AddFavouritesModal_modalCloseIcon__1Vp2C {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
  color: #4d4d4d;
}

.AddFavouritesModal_modalBody__1xP_N {
  display: flex;
  flex-wrap: wrap;
  width: 733px;
  margin: 15px 53px;
}

.AddFavouritesModal_favourite__1wEi8 {
  flex-direction: row;
  flex-grow: 1;
  width: 25%;
  margin-bottom: 27px;
}

.AddFavouritesModal_favouriteName__A9VzI {
  font-size: 16px;
}

.DashboardForNonAdmin_pageName__1IeKt {
  min-width: 200px;
}

.DashboardForNonAdmin_main__2X9R1 {
  min-width: 700px;
  margin-top: 60px;
}

.DashboardForNonAdmin_column__vBaFD {
  float: left;
  width: 33%;
  margin-bottom: 40px;
}

/* Clear floats after the columns */
.DashboardForNonAdmin_row__3vaUk:after {
  content: "";
  display: table;
  clear: both;
}

.AddComplimentaryFoodModal_modal__1AzHo {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 820px;
  min-height: 662px;
}

.AddComplimentaryFoodModal_input__1u8Zp {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 25px;
  color: #4d4d4d !important;
}

.AddComplimentaryFoodModal_modalBody__25WkJ {
  min-height: 423px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddComplimentaryFoodModal_inputOptions__3QsYE {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.AddComplimentaryFoodModal_icon__2PUyx {
  right: 10% !important;
}

.AddComplimentaryFoodModal_autocompleteRoot__HWsH1 {
  margin-right: 10px;
}

.AddComplimentaryFoodModal_autocomplete__1Aipq {
  width: 179px !important;
}

.AddComplimentaryFoodModal_rowNumber__emwa_ {
  margin-right: 10px;
}

.AddComplimentaryFoodModal_materialUnit__L09i2 {
  width: 450px;
  margin-left: 10px !important;
}

.AddComplimentaryFoodModal_addMore__1TsfM {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.AddStockTransferModal_modal__1eL0V {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 839px;
  min-height: 662px;
}

.AddStockTransferModal_input__1P7Py {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 25px;
  color: #4d4d4d !important;
}

.AddStockTransferModal_modalBody__ZzqVm {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddStockTransferModal_inputOptions__158bW {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.AddStockTransferModal_icon__1fQCl {
  right: 10% !important;
}

.AddStockTransferModal_autocomplete__ekX_S {
  width: 179px !important;
}

.AddStockTransferModal_rowNumber__2aa6L {
  margin-right: 10px;
}

.AddStockTransferModal_addMore__2eY10 {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.AddStockTransferModal_autocompleteRoot__1nmMT {
  width: 32% !important;
}

.AddStockTransferModal_materialUnit__27I3D {
  width: 215px;
  margin-left: 20px !important;
  margin-right: 20px !important;
}

.AddNewMaterialModal_modal__3XgB2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 650px;
  min-height: 662px;
}

.AddNewMaterialModal_input__3HpKF {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  margin-left: 10px !important;
}

.AddNewMaterialModal_materialName__2OYEF {
  width: 186px !important;
}

.AddNewMaterialModal_materialUnit__wSTy4 {
  width: 150px !important;
}

.AddNewMaterialModal_classification__e-nxp {
  width: 131px !important;
}

.AddNewMaterialModal_modalBody__xBJjb {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddNewMaterialModal_rowNumber__2_Rj- {
  margin-right: 10px;
}

.AddNewMaterialModal_inputOptions__3CvZ_ {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 752px;
  margin-top: 20px;
}

.AddNewMaterialModal_icon__1eHDN {
  right: 10% !important;
}

.AddNewMaterialModal_addMore___xCUk {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.Stock_pageName__dsfE2 {
  min-width: 200px;
}

.StockTable_table__NI9zW {
  min-width: 892px !important;
  min-height: 316px !important;
}

.ModifyStockModal_modal__1rLKk {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  min-height: 662px;
}

.ModifyStockModal_modalRoot__27C9a {
  overflow: scroll;
  border-color: white;
}

.ModifyStockModal_modalTitleBox__1NVoS {
  height: 140px;
  min-width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ModifyStockModal_modalTitleText__3QlHa {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ModifyStockModal_modalNav__15LXb {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ModifyStockModal_modalCloseIcon__35oaJ {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.ModifyStockModal_backToEntriesBtn__Xa1OL {
  margin-right: auto;
  padding-left: 18px;
  padding-top: 23px;
}

.ModifyStockModal_toGraphBtn__YvOzK {
  float: right;
  padding: 5px 30px 10px 0;
}

.ModifyStockModal_text__UCYw3 {
  font-size: medium;
}

.ModifyStockModal_date__124jb {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.ModifyStockModal_branch__Jkm78 {
  font-size: small;
}

.ModifyStockModal_quantityText__33MxY {
  width: 50px;
  text-align: center;
}
.ModifyStockModal_icon__1ICis {
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.ModifyStockModal_submitButton__121cs {
  width: 150px;
  height: 30px;
}

.ConfirmationDialog_approval__3j4Pu {
  padding-top: 20px;
  min-width: 512px;
  min-height: 219px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ConfirmationDialog_body__2H2ry {
  display: flex;
  flex-direction: row;
}

.ConfirmationDialog_approvalButton__3fUIK {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
}

.ConfirmationDialog_approvalText__kj8g0 {
  font-size: 28px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 42px;
  margin-left: 30px;
  margin-right: 30px;
}

.ConfirmationDialog_modalCloseIcon__3VkwU {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 90%;
}

.SalesReportTable_table__2Roxd {
  min-width: 892px !important;
  min-height: 316px !important;
}

.ModifySaleModal_modal__E6v4Z {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 662px;
}

.ModifySaleModal_modalRoot__10KLJ {
  overflow: scroll;
  border-color: white;
}

.ModifySaleModal_modalTitleBox__j_5ym {
  width: 700px;
  height: 140px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ModifySaleModal_modalTitleText__2HhSq {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ModifySaleModal_modalNav__3V0C8 {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ModifySaleModal_modalCloseIcon__3VST3 {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.ModifySaleModal_backToEntriesBtn__2sD_1 {
  margin-right: auto;
  padding-left: 18px;
  padding-top: 23px;
}
.ModifySaleModal_toGraphBtn__2re25 {
  float: right;
  padding: 5px 20px 5px 0;
}

.ModifySaleModal_text__2ha_x {
  font-size: medium;
}

.ModifySaleModal_date__13lF9 {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.ModifySaleModal_branch__3DLF7 {
  font-size: small;
}

.ModifySaleModal_quantityText__SlFi5 {
  width: 50px;
  text-align: center;
}
.ModifySaleModal_icon__-qyIl {
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.ModifySaleModal_submitButton__3J_hY {
  width: 150px;
  height: 30px;
}

.SalesReport_pageName__3cw6m {
  min-width: 200px;
}

.SalesReport_date__2bJM5 {
  margin-left: 750px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  color: #4d4d4d;
}

.WastageTable_table__2VkN3 {
  min-width: 892px !important;
  min-height: 316px !important;
}

.ModifyWastageModal_modal__4eIQi {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 662px;
}

.ModifyWastageModal_modalRoot__3o_df {
  overflow: scroll;
  border-color: white;
}

.ModifyWastageModal_modalTitleBox__3aSs9 {
  width: 700px;
  height: 140px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ModifyWastageModal_modalTitleText__32rxy {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ModifyWastageModal_modalNav__3TlCp {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.ModifyWastageModal_modalCloseIcon__1ehdJ {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.ModifyWastageModal_backToEntriesBtn__3GWRl {
  margin-right: auto;
  padding-left: 18px;
  padding-top: 23px;
}
.ModifyWastageModal_toGraphBtn__2aHTw {
  float: right;
  padding: 5px 20px 5px 0;
}

.ModifyWastageModal_text__1RemM {
  font-size: medium;
}

.ModifyWastageModal_date__B5VRa {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.ModifyWastageModal_branch__zsclo {
  font-size: small;
}

.ModifyWastageModal_quantityText__FuuQU {
  width: 50px;
  text-align: center;
}
.ModifyWastageModal_icon__OYfCg {
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.ModifyWastageModal_submitButton__J5m05 {
  width: 150px;
  height: 30px;
}

.Wastage_pageName__2-JvK {
  min-width: 200px;
}

.Receipe_pageName__3VuQx {
  min-width: 200px;
}

.ReceipeTable_table__pqWg3 {
  min-width: 892px !important;
  min-height: 600px !important;
}

.ReceipeModal_modal__3QGUp {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 591px;
  min-height: 662px;
}

.ReceipeModal_modalRoot__3_WGP {
  overflow: scroll;
  border-color: white;
}

.ReceipeModal_modalTitleBox__10GJd {
  width: 591px;
  height: 95px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ReceipeModal_modalTitleText__31YlL {
  margin-top: 15px;
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ReceipeModal_edit__1BygL {
  margin-top: 30px;
  margin-left: 30px;
}

.ReceipeModal_modalCloseIcon__1jKaL {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
  color: white;
}

.ReceipeModal_content__2U0zn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.ReceipeModal_levelZeroText__17PP- {
  margin: 11px 10px 11px 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
}

.ReceipeModal_levelZeroDetail__3QhJ3 {
  background-color: #fce3d1;
  min-width: 561px;
  height: 47px;
  margin-top: 20px;
  margin-right: 14px;
  margin-left: 14px;
}

.ReceipeModal_levelTwoOrGreater__3AQse {
  font-weight: 500;
}

.ReceipeModal_right__3V909 {
  display: flex;
}

.ReceipeModal_index__EDSqD {
  margin-right: 7px;
}

.ReceipeModal_detail__2tKD- {
  display: flex;
  padding: 0;
  margin-left: 14px;
  min-width: 561px;
}

.ReceipeModal_line__obXdS {
  border-bottom: 1px dashed #4d4d4d;
  height: 0px;
  margin-top: 20px;
}

.ReceipeModal_grow__1vM9d {
  flex-grow: 1;
}

.ReceipeModal_innerDiv__7F4fT {
  display: flex;
}

.ReceipeModal_toggleButton__2faS4 {
  width: 85px;
}

.ReceipeModal_quantity__24Dbs {
  font-size: medium;
}

.UpdateRecipeModal_modal__EessK {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 500px;
}

.UpdateRecipeModal_materialUnit__1EsTL {
  margin-top: 8px !important;
  min-width: 150px !important;
}

.UpdateRecipeModal_input__1MFHL {
  background-color: white;
  width: 209px !important;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 25px;
}

.UpdateRecipeModal_quantity__3gT1b {
  width: 300px;
}

.UpdateRecipeModal_modalBody__nbkYO {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.UpdateRecipeModal_rowNumber__Bnthd {
  margin-right: 10px;
}

.UpdateRecipeModal_radioSelection__hvCzs {
  align-self: flex-start;
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.UpdateRecipeModal_inputOptions__vDsxd {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.UpdateRecipeModal_icon__2ArdR {
  right: 10% !important;
}

.UpdateRecipeModal_autocompleteRoot__3N6y5 {
  margin-right: 25px;
}

.UpdateRecipeModal_autocomplete__1FK6C {
  width: 150px !important;
}

.UpdateRecipeModal_addMore__3KdlM {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.UpdateRecipeModal_confirmationTitle__3a0wW {
  font-size: 24px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.UpdateRecipeModal_confirmationBody__3LqUk {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.UpdateRecipeModal_materialItem__1C1-X {
  width: 100%;
}

.UpdateRecipeModal_materialsListContainer__1yrNS {
  display: flex;
  flex-wrap: wrap;
}

.UpdateRecipeModal_materialsListContainer__1yrNS>.UpdateRecipeModal_materialItem__1C1-X {
  flex: 25% 1;
  margin-bottom: 3px;
}

.AddRecipeModal_modal__EqqU8 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 500px;
}

.AddRecipeModal_materialUnit__3jrSb {
  margin-top: 8px !important;
  min-width: 150px !important;
}

.AddRecipeModal_quantity__2VDVN {
  width: 300px;
}

.AddRecipeModal_modalBody__3BPWy {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddRecipeModal_rowNumber__2A9Gv {
  margin-right: 10px;
}

.AddRecipeModal_radioSelection__19bws {
  align-self: flex-start;
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.AddRecipeModal_ingredients__3j4Bs {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.AddRecipeModal_inputOptions__7_I4P {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
}

.AddRecipeModal_icon__3TsyN {
  right: 10% !important;
}

.AddRecipeModal_autocompleteRoot__n71Id {
  margin-right: 15px;
}

.AddRecipeModal_autocomplete__3BHQN {
  width: 80px !important;
}

.AddRecipeModal_addMore__1TUnY {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.StaffFoodTable_table__2jePC {
  min-width: 892px !important;
  min-height: 316px !important;
}

.StaffFoodTable_quantityText__85RSv {
  width: 50px;
  text-align: center;
}

.StaffFood_pageName__37cas {
  min-width: 200px;
}

.ComplimentaryFood_pageName__1uts9 {
  min-width: 200px;
}

.ComplimentaryFoodTable_container__1cqFK {
  background-color: #ffffff;
  padding: 7px 18px;
  margin-top: 13px;
  margin-bottom: 26px;
}

.ComplimentaryFoodTable_table__3JEsO {
  min-width: 892px !important;
  min-height: 316px !important;
}

.ComplimentaryFoodTable_header__99P-F {
  height: 34px;
  font-size: 15px;
  font-weight: bold !important;
  background-color: #f9f9f9;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 34px;
}

.ComplimentaryFoodTable_group__i3zsV {
  border: 1px;
}

.ComplimentaryFoodTable_cell__2J77J {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.ComplimentaryFoodTable_center__2oHeK {
  text-align: center !important;
}

.ComplimentaryFoodTable_fromTo__1G1YN {
  float: left;
  border: 1px;
}

.ComplimentaryFoodTable_widthDates__2FSxu {
  width: 94px !important;
  opacity: 0.8;
}

.ComplimentaryFoodTable_input__37TEz {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.ComplimentaryFoodTable_mainDate__1oG0N {
  background-color: white;
  font-size: 12px;
  text-align: center;
}

.ComplimentaryFoodTable_searchIcon__3D_0d {
  width: 12px;
  height: 12px;
}

.ComplimentaryFoodTable_loadingDiv__3WPd8 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ComplimentaryFoodTable_loading__3s_d2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ComplimentaryFoodTable_pink__1SZsk {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.ComplimentaryFoodTable_green__1AwXq {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.ComplimentaryFoodTable_lastrow__2LRWM {
  border-bottom: 2px solid #a1a1a1;
}

.ComplimentaryFoodTable_list__3TO5e {
  overflow: visible !important;
  height: unset !important;
}

.ComplimentaryFoodTable_table__3JEsO {
  border-collapse: collapse !important;
}

.ComplimentaryFoodTable_headerBox__tV2kB {
  display: inline-flex;
}

.ComplimentaryFoodTable_leftAlign__XnrIY {
  text-align: left;
}

.ComplimentaryFoodTable_pendingButton__1QbVj {
  float: right;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  height: 24px;
  background-color: #4d4d4d !important;
  color: white !important;
}

.CommentBox_commentParent__2UbVm {
  position: relative;
}

.CommentBox_commentDiv__26CSv {
  position: absolute;
  padding: 10px;
  width: 190px;
  height: 90px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  left: 120px;
  top: -5px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px #0000003a;
  text-align: left !important;
}

.CommentBox_employeeName__GDS31 {
  font-size: 16px;
  font-weight: bold;
}

.CommentBox_employeeName__GDS31 {
  font-size: 16px;
  font-weight: bold;
}

.CommentBox_modalCloseIcon__2U6pV {
  color: #4d4d4d;
  margin-left: auto;
}
.CommentBox_date__2AiiO {
  font-size: 12px;
}

.CommentBox_iconButton__3dcf6 {
  padding: 0px !important;
}

.CommentBox_commentText__1-70W {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
}

.CommentBox_miniBody__3o88w {
  display: flex;
  flex-direction: row;
}

.AddUserModal_modal__19YQ9 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 400px;
}

.AddUserModal_input__1q6U7 {
  background-color: white;
  opacity: 0.7;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #4d4d4d !important;
}

.AddUserModal_modalBody__2XNFT {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddUserModal_label__3ktYx {
  margin-top: 16px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
}

.AddBranchModal_modal__1O3i2 {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 400px;
}

.AddBranchModal_input__3talc {
  background-color: white;
  min-width: 500px !important;
  font-size: 14px !important;
  border-radius: 4px;
  color: #000000 !important;
}

.AddBranchModal_modalBody__2Ex5d {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.AddBranchModal_addMore__ArPId {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
}

.BranchTable_table__1aGVD {
  min-width: 892px !important;
  min-height: 250px !important;
}

.Settings_header__1YckW {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
}

.Settings_container__2zz29 {
  margin-left: 53px;
  margin-top: 24px;
}

.Settings_rightSection__2_PbU {
  margin-left: 60px;
  margin-top: 13px;
}

.Settings_content__1Ogn1 {
  display: flex;
}

.EmployeesTable_table__fJ4Wb {
  min-width: 892px !important;
  min-height: 250px !important;
}

.StockTransfers_pageName__3UZtk {
  min-width: 200px;
}

.StockTransferTable_table__2Tk2d {
  min-width: 892px !important;
  min-height: 316px !important;
}

.StockTransferTable_pink__3_52f {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.StockTransferTable_green__lpE6A {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.StockTransferTable_noCenter__3l7f0 {
  padding-left: 20px;
  text-align: left;
}

.ApprovalDialogWithComment_approval__1wy0z {
  width: 512px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.ApprovalDialogWithComment_body__2Nm7d {
  display: flex;
  flex-direction: row;
}

.ApprovalDialogWithComment_approvalButton__2WlGE {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
  margin-bottom: 25px !important;
}

.ApprovalDialogWithComment_line__1loGs {
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #cecece;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.ApprovalDialogWithComment_line__1loGs span {
  color: #cecece;
  background: #fff;
  padding: 0 10px;
}

.ApprovalDialogWithComment_commentButton__iuD7L {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  text-align: center;
  font-size: 21px !important;
}

.ApprovalDialogWithComment_comment__2sLG9 {
  width: 430px;
  margin-bottom: 20px;
}

.ApprovalDialogWithComment_approvalText__15Ha9 {
  font-size: 28px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 42px;
}

.ApprovalDialogWithComment_modalCloseIcon__2Ama2 {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 450px;
}

.CommentsList_commentParent__2XR3O {
  position: relative;
}

.CommentsList_comment__l6LYy {
  margin: 8px;
}

.CommentsList_commentDiv__3Iz0J {
  position: absolute;
  padding: 12px;
  width: 235px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  left: 120px;
  top: -5px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px #0000003a;
  margin-bottom: 5px;
  line-height: 20px;
}

.CommentsList_employeeName__2a-tI {
  font-size: 16px;
  font-weight: bold;
}

.CommentsList_employeeName__2a-tI {
  font-size: 16px;
  font-weight: bold;
}

.CommentsList_modalCloseIcon__2VHj3 {
  color: #4d4d4d;
  margin-left: auto;
}
.CommentsList_date__1bzzi {
  font-size: 12px;
  text-align: left;
}

.CommentsList_iconButton__2_5G_ {
  padding: 0px !important;
  top: -15px;
  right: -5px;
}

.CommentsList_commentText__1RN2j {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}

.CommentsList_miniBody__14qXw {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.CommentsList_commentBox__w8QRd {
  margin-top: 3px;
  width: 215px;
}

.CommentsList_divider__pW54J {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.CommentsList_dividerButton__25VXg {
  height: 2px !important;
  background-color: #000000 !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.CommentsList_commentButton__1ByIq {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  margin-top: 12px !important;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  text-align: center;
}

.CommentsList_approveButton__3ECDO {
  margin-top: 12px !important;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  text-align: center;
}

.SideBar_root__wHJSJ {
  height: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 195px;
  flex: 1 1;
}

.SideBar_img__382g0 {
  width: 150px;
  height: 30px;
}

.SideBar_header__36IHp {
  padding: 12px;
}

.SideBarNav_nav__3676n {
  margin-bottom: 2px;
  overflow-y: scroll;
}

.SideBarNav_nav__3676n::-webkit-scrollbar {
  display: none;
}

.SideBarNav_root__hMBE8 {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 260px;
}

.SideBarNav_list__32cFX {
  padding: 0 !important;
}

.SideBarNav_icon__AejRY {
  margin-left: 5px;
  margin-right: 5px;
}

.SideBarNav_button__ah_c- {
  width: 100%;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.SideBarNav_item__t6NZQ {
  display: flex;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.SideBarNav_title__3tBPq {
  padding-bottom: 6px;
  justify-content: flex-start;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  font-weight: bold;
  text-align: left;
  height: 17px;
  opacity: 1;
}

.HomeLayout_root__1II39 {
  display: flex;
  flex-direction: row;
}

.HomeLayout_content__1UrL6 {
  background-color: #f6f6f6;
  flex: 1 1;
  margin-left: 195px;
}

.HomeLayout_header__22F0c {
  background-color: #ffffff;
  height: 58px;
}

.HomeLayout_date__2tLgj {
  float: left;
  padding-top: 18px;
  padding-left: 53px;
  color: #4d4d4d;
  font-size: 15px;
}

.HomeLayout_welcome__4CcKh {
  float: right;
  padding-right: 70px;
  padding-top: 18px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 15px;
}


.ColumnGraph_chart__1r8dL {
  width: 893px;
  height: 308px;
  background: #ffffff;
}

.ColumnGraph_body__zjDYc {
  margin-top: 50px;
  background: #ffffff;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.ColumnGraph_header__rA1YS {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.ColumnGraph_btn__awkum {
  font-weight: bolder;
  height: 20px;
}

.ColumnGraph_btnHidden__elXw2 {
  font-weight: bolder;
  visibility: hidden;
  height: 20px;
}

.ColumnGraph_graphHeader__1avrd {
  margin-left: 26px;
  margin-top: 8px;
  display: flex;
}

.ColumnGraph_headerBranch__X7EbF {
  float: left;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}

.ColumnGraph_headerDate__2h1u0 {
  font-weight: bold;
}

.ColumnGraph_headerTitle__2yRhR {
  font-weight: 500;
}

.ColumnGraph_headerCentral__dPwhi {
  flex-grow: 1;
  color: #4d4d4d;
  font-size: 13px;
}

.style_react-resizable__3ppDO {
  max-width: 100%;
}

.style_react-resizable-handle__35ZCv {
  bottom: -10px;
  right: -10px;
}

.GraphsHeaderMonthly_datepicker__2anAC {
  float: right;
}

.GraphsHeaderMonthly_headerBranch__pgM-X {
  float: left;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}

.GraphsHeaderMonthly_headerDate__1C76X {
  font-weight: bold;
}

.GraphsHeaderMonthly_headerTitle__3pKnO {
  font-weight: 500;
}

.GraphsHeaderMonthly_headerCentral__2noaM {
  margin-left: 20%;
  flex-grow: 1;
  color: #4d4d4d;
  font-size: 13px;
}

.MaterialSelector_root__ziQ0w {
  background-color: #4d4d4d;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MaterialSelector_select__4iGTD {
  margin: 0 auto;
  right: 13%;
}




.Report_pageName__jplg9 {
  min-width: 200px;
}

.DailyEntryReportTable_table__30qV- {
  min-width: 850px !important;
  min-height: 316px !important;
}

.DailyEntryReportTable_header__3fAc- {
  border: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.DailyEntryReportTable_widthDates__rMDy9 {
  width: 94px !important;
  opacity: 0.8;
}

.DailyEntryReportTable_input__2e2dr {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.DailyEntryReportTable_submitButton__3WtMD {
  width: 60px;
  height: 23px;
}

.DailyEntryReportTable_container__3AW2H {
  width: 780px;
}

.OtherEntryReportTable_table__1ZDvM {
  width: 650px !important;
  min-height: 316px !important;
}

.OtherEntryReportTable_header__1Ig6R {
  border: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.OtherEntryReportTable_widthDates__M16Hv {
  width: 94px !important;
  opacity: 0.8;
}

.OtherEntryReportTable_input__RJ5a2 {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.OtherEntryReportTable_submitButton__2kgHO {
  width: 60px;
  height: 23px;
}

.OtherEntryReportTable_container__39A2Y {
  width: 680px;
}

.ReportsWithDateRange_header__iG-Vn {
  border: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.ReportsWithDateRange_widthDates__2Cf5F {
  width: 94px !important;
  opacity: 0.8;
}

.ReportsWithDateRange_input__33OfJ {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.ReportsWithDateRange_submitButton__3qmzS {
  width: 60px;
  height: 23px;
}

.ReportsWithDateRange_container__1wsBp {
  width: 780px;
  text-align: left;
}

.ReportsWithDateRange_autocompleteRoot__JjUoD {
  margin: 0 0 20px 0;
  width: 365px !important;
  height: 50px;
}

.ReportsWithDateRange_autocomplete__2xDqG {
  width: 179px !important;
}

.AddReminderModal_modal__3RoyI {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 1150px;
  min-height: 500px;
}

.AddReminderModal_modalBody__PsYMx {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
}

.AddReminderModal_section__2Gz7o {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.AddReminderModal_divider__6iWMM {
  border-left: 1px solid #cecece;
  margin-left: 30px;
  margin-right: 30px;
  height: 400px;
}

.AddReminderModal_input__1YpEZ {
  margin: 8px !important;
  width: 300px !important;
}

.AddReminderModal_datesWidth__3z8XV {
  width: 300px !important;
}

.AddReminderModal_defaultMargin__307dT {
  margin: 8px !important;
}

.AddReminderModal_delete__2d3C5 {
  padding-left: 23px !important;
  float: left;
}

.AddReminderModal_title__iGPv_ {
  padding-right: 150px !important;
}

.AddReminderModal_confirmationTitle__1v_Q0 {
  font-size: 28px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.AddReminderModal_confirmationBody__1mc9O {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.DatePickerHeaderForReminders_root__pGwPN {
  background-color: #4d4d4d;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DatePickerHeaderForReminders_select__1cP0I {
  margin-left: 360px;
  right: 13%;
}

.ViewReminderModal_modal__5y0WF {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 500px;
}

.ViewReminderModal_modalRoot__2Yf7p {
  margin: 20px !important;
}

.ViewReminderModal_modalTitleBox__31z4M {
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.ViewReminderModal_modalBody__qkfME {
  opacity: 1;
  display: flex;
  flex-direction: column;
  width: 700px;
}

.ViewReminderModal_eventDetails__1ubzg {
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;
}

.ViewReminderModal_section__3BAcC {
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.ViewReminderModal_modalTitleText__3VGX5 {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ViewReminderModal_modalCloseIcon__1WkmJ {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.ViewReminderModal_textFieldMargin__1N7VC {
  margin-bottom: 10px !important;
}

.ViewReminderModal_userInput__3zypP {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.ViewReminderModal_submitButton__zTUr2 {
  width: 220px;
  align-self: center;
  margin-top: 30px !important;
}

.ViewReminderModal_edit__3Ezu5 {
  padding-left: 23px;
  float: left;
}

.ViewReminderNonEditableModal_eventViewContainer__3hkC4 {
  height: 580px;
  width: 550px;
}

.ViewReminderNonEditableModal_header__2yV-x {
  height: 80px;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.ViewReminderNonEditableModal_headerText__1uaSP {
  color: inherit;
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.ViewReminderNonEditableModal_modalCloseIcon__2g3O7 {
  margin-left: auto;
}

.ViewReminderNonEditableModal_content__3q8Pf {
  padding-top: 20px;
  display: flex;
}

.ViewReminderNonEditableModal_section__2ZcZp {
  width: 50%;
  padding-left: 30px;
}

.ViewReminderNonEditableModal_divider__3aJ74 {
  border-left: 1px solid #cecece;
  height: 460px;
}

.ViewReminderNonEditableModal_textFieldMargin__134T6 {
  margin-bottom: 10px !important;
}

.ViewReminderNonEditableModal_invoice__1sgF2 {
  height: 270px;
  width: 210px;
}

.ViewReminderNonEditableModal_invoice__1sgF2 img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(187, 187, 187, 0.19);
}

.RemindersTable_table__3BZkB {
  min-width: 852px !important;
  min-height: 316px !important;
}

.RemindersTable_red__3ejEr {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.RemindersTable_green__zDMVp {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.RemindersTable_yellow__1hz1n {
  background-color: #ffca83 !important;
  text-transform: capitalize;
}

.RemindersTable_blue__8TLdy {
  background-color: #a7ebff !important;
  text-transform: capitalize;
}

.Reminders_pageName__2PKO- {
  min-width: 200px;
}

.Reminders_button__3GlSp {
  width: 190px;
  height: 60px;
  border: 0px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 15px;
}

.Reminders_button__3GlSp:hover {
  background-color: #fafafa;
  font-weight: 600;
}

.Reminders_buttonTitle__2zNX_ {
  margin-left: 9px;
  text-align: left;
}

.Reminders_icon__2MwxT {
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.ScrollableList_scrollableList__3VU6M {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.ScrollableList_header__2xknc {
  background-color: #eaeaea;
  height: 54px;
  width: 350px;
  position: relative;
  color: #4d4d4d;
  font: normal bold 16px/30px "Source Sans Pro";
}

.ScrollableList_headerText__2ylx3 {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ScrollableList_addIcon__5RUCa {
  position: absolute;
  float: right;
  right: 20px;
  bottom: 20px;
}

.ScrollableList_list__3vkRX {
  overflow-y: scroll;
  width: 350px;
  flex-grow: 1;
}

.ScrollableList_listItem__2h-aa {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  height: auto;
  width: 280px;
  border-bottom: 1px solid #9b9b9b29;
  padding: 0 20px 0 20px;
}

.ScrollableList_listItemText__727d7 {
  padding: 5px 0 5px 0;
  inline-size: 200px;
  font: normal bold 15px/30px "Source Sans Pro";
  overflow-wrap: break-word;
  text-align: left;
}

.ScrollableList_deleteIcon__2kvWp,
.ScrollableList_addEntry__25a9h {
  cursor: pointer;
}

.AddEventCategoriesListView_container__3ZgH0 {
  margin-top: 20px;
  width: 1130px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddEventCategoriesListView_scrollableListContainer__1dGG9 {
  height: 600px;
  width: 1150px;
  display: flex;
  justify-content: space-between;
}

.AddEventCategoriesListView_modalRoot__fGkez {
  overflow: scroll;
  border-color: white;
}

.AddEventCategoriesListView_addModalcontent__36AAO {
  width: 512px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.AddEventCategoriesListView_body__2SYQM {
  display: flex;
  flex-direction: row;
}

.AddEventCategoriesListView_modalBody__5z8B7 {
  width: 350px;
  margin-bottom: 30px;
}

.AddEventCategoriesListView_addSubmitBtn__px8Zt {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
}

.AddEventCategoriesListView_addItemInput__1F3jw {
  border: 1px solid #cecece;
  margin-bottom: 42px;
  padding: 15px;
  font-size: 18px;
  width: 300px;
}

.AddEventCategoriesListView_addModalCloseIcon__2MCto {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 450px;
}

.DatePickerHeaderForPurchaseOrders_root__3Cr6V {
  background-color: #4d4d4d;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.DatePickerHeaderForPurchaseOrders_select__9PUlI {
  margin-left: 280px;
  right: 13%;
}

.PurchaseOrders_pageName__2rkHG {
  min-width: 200px;
}

.PurchaseOrders_button__3u0I5 {
  width: 190px;
  height: 60px;
  border: 0px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 15px;
}

.PurchaseOrders_button__3u0I5:hover {
  background-color: #fafafa;
  font-weight: 600;
}

.PurchaseOrders_buttonTitle__3uW3a {
  margin-left: 9px;
  text-align: left;
}

.PurchaseOrders_icon__3AFRy {
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.PurchaseOrders_genConsolidatedPoBtn__36Wmq {
  left: 140px;
  width: 210px;
  height: 40px;
  background-color: #1d68ed !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: bold;
}

.PurchaseOrders_autocompleteRoot__1OakG {
  margin: 0 0 30px 0;
  width: 365px !important;
  height: 50px;
}

.PurchaseOrders_autocomplete__3S4dR {
  width: 179px !important;
}

.PurchaseOrderTable_lightRed__1dyCG {
  background-color: #fc40595d !important;
  text-transform: capitalize;
}

.PurchaseOrderTable_green__2Eg4p {
  background-color: #69e4a746 !important;
  text-transform: capitalize;
}

.PurchaseOrderTable_yellow__2P9Dq {
  background-color: #ffc98360 !important;
  text-transform: capitalize;
}

.PurchaseOrderTable_blue__2SHdP {
  background-color: #a7eaff60 !important;
  text-transform: capitalize;
}

.PurchaseOrderTable_addSubmitBtn__3r7BI {
  width: 100px;
  height: 35px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 12px !important;
}

.PurchaseOrderTable_table__36aB7 {
  min-width: 892px !important;
  min-height: 250px !important;
}

.ViewSupplier_pageName__27esO {
  min-width: 200px;
  margin-left: 22px;
}

.ViewSupplier_backButton__1709K {
  padding: 0 30px !important;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  height: 35px;
  width: 100px;
}

.ViewSuppliersTable_table__SFQpu {
  min-width: 892px !important;
  min-height: 250px !important;
}

.ViewSuppliersTable_addSubmitBtn__3sX4b {
  width: 100px;
  height: 35px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 12px !important;
}
.SupplierForm_form__18iTf {
  width: 1126px;
  margin-top: 27px;
}

.SupplierForm_header__1D00p {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.SupplierForm_headText__38eGv {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.SupplierForm_formBody__cuIoY {
  height: auto;
  background: #ffffff;
  padding: 25px;
}

.SupplierForm_textBox__3RSyQ {
  width: 340px;
  margin-left: 10px !important;
}

.SupplierForm_textArea__3RvJl {
  width: 340px;
  height: 150px;
  margin-left: 50px !important;
}

.SupplierForm_footer__3JoZI {
  height: 80px;
  padding-left: 25px;
  padding-right: 25px;
  background: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.SupplierForm_excelButtons__TwKpu {
  display: flex;
  justify-content: space-between;
}

.SupplierForm_excelButton__28kXA {
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #ffffff !important;
  font-size: 15px !important;
  text-align: center;
}

.SupplierForm_excelIcon__1EsRu {
  height: 27px;
  width: 27px;
}

.SupplierForm_selectItemsButton__3kmwD {
  width: 200px;
  height: 50px;
  border: 2px solid #ed731d !important;
  color: #ed731d !important;
  font-weight: "bold" !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #2c28281c !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

.SupplierForm_inputDiv___LQUs {
  display: flex;
  width: 475px;
  justify-content: space-between;
}

.SupplierForm_inputLabel__LnVBb {
  margin-top: 16px;
  font-weight: bold;
}

.SupplierForm_hidden___74OY {
  visibility: hidden;
}

.SupplierMaterialsForm_form__1GUtR {
  width: 1126px;
  margin-top: 27px;
  height: auto;
}

.SupplierMaterialsForm_header__1vmZj {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.SupplierMaterialsForm_headText__HCDhp {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.SupplierMaterialsForm_inputOptions__2ud-4 {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 15px;
  margin-left: 31px;
}

.SupplierMaterialsForm_rowNumber__3MsRT {
  margin-right: 10px;
}

.SupplierMaterialsForm_autocompleteRoot__2P4RK {
  margin-right: 10px;
  width: 500px !important;
  height: 50px;
}

.SupplierMaterialsForm_autocomplete__3kMNx {
  width: 179px !important;
  color: black !important;
}

.SupplierMaterialsForm_addItem__2GJDk {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-right: 20px;
  float: left;
}

.SupplierMaterialsForm_addSupplierButton__1Qy5G {
  position: absolute;
  left: 762px;
  bottom: 60px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.SupplierMaterialsForm_addItemDiv__pUW_z {
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-right: 55px;
}

.AddSupplierComponent_pageName__LA6ow {
  min-width: 200px;
  margin-left: 22px;
}

.AddSupplierComponent_header__Q9dBp {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.AddSupplierComponent_backButton__38lzZ {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.EditSupplierComponent_pageName__2ifYa {
  min-width: 200px;
  margin-left: 22px;
}

.EditSupplierComponent_header__3153p {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.EditSupplierComponent_backButton__3yi0r {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.EditSupplierComponent_loadingDiv__3RXr_ {
  display: flex;
  align-items: center;
  justify-content: center;
}

.EditSupplierComponent_loading__371RE {
  display: flex;
  align-items: center;
  justify-content: center;
}

.GeneratePurchaseOrderComponent_pageName__LGJ6C {
  min-width: 200px;
  margin-left: 22px;
}

.GeneratePurchaseOrderComponent_header__3hwWK {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.GeneratePurchaseOrderComponent_backButton__3jEal {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.GeneratePurchaseOrderForm_form__3mmU1 {
  width: 1126px;
  margin-top: 27px;
}

.GeneratePurchaseOrderForm_header__WCInY {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.GeneratePurchaseOrderForm_headText__3SGyg {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.GeneratePurchaseOrderForm_formBody__10POK {
  height: auto;
  background: #ffffff;
  padding: 25px;
}

.GeneratePurchaseOrderForm_textBox__1CpIF {
  width: 340px;
  margin-top: 5px !important;
}

.GeneratePurchaseOrderForm_footer__1Y40e {
  height: 80px;
  padding-left: 25px;
  padding-right: 25px;
  background: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.GeneratePurchaseOrderForm_excelButtons__ORhSB {
  display: flex;
  justify-content: space-between;
}

.GeneratePurchaseOrderForm_excelButton__2iAxP {
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #ffffff !important;
  font-size: 15px !important;
  text-align: center;
}

.GeneratePurchaseOrderForm_excelIcon__30ZYy {
  height: 27px;
  width: 27px;
}

.GeneratePurchaseOrderForm_selectItemsButton__EFZdS {
  width: 200px;
  height: 50px;
  border: 2px solid #ed731d !important;
  color: #ed731d !important;
  font-weight: "bold" !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #2c28281c !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

.GeneratePurchaseOrderForm_inputDiv__1ywL1 {
  width: 465px;
  text-align: left;
}

.GeneratePurchaseOrderForm_inputLabel__lx303 {
  margin-top: 16px;
  font-weight: bold;
}

.GeneratePurchaseOrderForm_autocompleteRoot__3b-50 {
  margin-right: 10px;
  width: 340px !important;
  height: 50px;
}

.GeneratePurchaseOrderForm_autocomplete__39ZVn {
  width: 179px !important;
}

.GeneratePurchaseOrderForm_datePicker__mPjA_ {
  width: 340px !important;
}

.GeneratePurchaseOrderMaterialsForm_form__pufip {
  width: 1126px;
  margin-top: 27px;
  height: auto;
  margin-bottom: 40px;
}

.GeneratePurchaseOrderMaterialsForm_header__xDDXw {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.GeneratePurchaseOrderMaterialsForm_headText__3lOFQ {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.GeneratePurchaseOrderMaterialsForm_inputOptions__3GBEr {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 15px;
  margin-left: 31px;
}

.GeneratePurchaseOrderMaterialsForm_rowNumber__1kw3X {
  margin-right: 10px;
}

.GeneratePurchaseOrderMaterialsForm_autocompleteRoot__168XH {
  margin-right: 10px;
  width: 500px !important;
}

.GeneratePurchaseOrderMaterialsForm_autocomplete__1Xovf {
  width: 179px !important;
}

.GeneratePurchaseOrderMaterialsForm_addItem__3r4AC {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-right: 20px;
  float: left;
}

.GeneratePurchaseOrderMaterialsForm_addSupplierButton__1UdQy {
  position: absolute;
  left: 820px;
  bottom: 60px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.GeneratePurchaseOrderMaterialsForm_addItemDiv__1y0ef {
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-right: 55px;
}

.GeneratePurchaseOrderMaterialsForm_materialUnit__oML3D {
  width: 500px;
  margin-top: 20px;
  margin-left: 10px !important;
}

.GeneratePurchaseOrderMaterialsForm_columns__3yG3x {
  display: flex;
  background: #eaeaea;
  height: 32px;
  font-weight: bold;
  color: #4d4d4d;
  justify-content: space-around;
  align-items: center;
}

.GeneratePurchaseOrderMaterialsForm_column__2fhKM {
  margin-left: -360px;
}

.GrnComponent_pageName__26mxV {
  min-width: 200px;
  margin-left: 22px;
}

.GrnComponent_header__1DJWZ {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  display: flex;
}

.GrnComponent_backButton__2iedl {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.GrnComponent_generateGrnButton__2rXAY {
  position: absolute;
  left: 580px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.GrnComponent_downloadButton__1xHLv {
  position: absolute;
  left: 580px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #9b9b9b !important;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.GrnComponent_details__2xgsK {
  width: 1100px;
  margin-top: 40px;
}

.GrnComponent_content__3jx6T {
  width: 1100px;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  margin-bottom: 13px;
}

.GrnComponent_card__1ObO_ {
  padding: 10px;
  width: 33%;
  left: auto;
  text-align: left;
}

.GrnComponent_inputCard__3GxJH {
  padding: 10px;
  width: 20%;
  left: auto;
  text-align: left;
}

.GrnComponent_cardHeadText__7aDuy {
  padding-left: 25px;
  padding-top: 7px;
  display: flex;
}

.GrnComponent_headDate__FX7Kx {
  font-weight: bold;
}

.GrnComponent_headSubHead__cUwHf {
  color: #4d4d4d;
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
}

.GrnComponent_headSubText__1BDaJ {
  margin-left: 20px;
  margin-top: 6px;
  font-weight: bold;
}

.GrnComponent_detailsHeader__2cdV9 {
  height: 32px;
  background-color: #eaeaea;
  color: #4d4d4d;
  display: flex;
}

.GrnComponent_orderDetails__go3A3 {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.GrnComponent_orderDetailsHeader__2Bp0c {
  font-weight: bold;
  font-style: italic;
}

.GrnComponent_orderDetailColumns__1nmVM {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: #eaeaea;
  height: 32px;
}

.GrnComponent_inputOptions__c8Npu {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
  margin-left: 15px;
}

.GrnComponent_rowNumber__2u7bE {
  margin-right: 5px;
}

.GrnComponent_materialUnit__3n5rX {
  width: 28% !important;
}

.GrnComponent_inputContainer__3IxK6 {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.GrnComponent_materials__3HZc7 {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

.GrnComponent_confirmationTitle__1JbAt {
  font-size: 24px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.GrnComponent_confirmationBody__3AbOl {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.GrnComponent_date__1vMi9 {
  margin-left: 20px;
  margin-top: 6px;
}

.ViewPurchaseOrder_pageName__lhAoc {
  min-width: 200px;
  margin-left: 22px;
}

.ViewPurchaseOrder_header__jvx0Y {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  display: flex;
}

.ViewPurchaseOrder_backButton__244n2 {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.ViewPurchaseOrder_generateGrnButton__2WtbD {
  position: absolute;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.ViewPurchaseOrder_details__3O0J6 {
  width: 1100px;
  margin-top: 40px;
}

.ViewPurchaseOrder_content__2nDPR {
  display: flex;
  height: 100px;
  justify-content: space-evenly;
  background-color: #fff;
  margin-bottom: 13px;
}

.ViewPurchaseOrder_card__h9HUG {
  padding: 10px;
  width: 33%;
  left: auto;
  text-align: left;
}

.ViewPurchaseOrder_cardHeadText__7Ikyx {
  padding-left: 25px;
  padding-top: 7px;
}

.ViewPurchaseOrder_headDate__3veEE {
  font-style: normal;
  font-weight: bold;
}

.ViewPurchaseOrder_headSubHead__3-5u9 {
  color: #4d4d4d;
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
}

.ViewPurchaseOrder_headSubText__1P0gV {
  margin-left: 20px;
  margin-top: 6px;
  font-weight: bold;
}

.ViewPurchaseOrder_detailsHeader__1XCY4 {
  height: 32px;
  background-color: #eaeaea;
  color: #4d4d4d;
  display: flex;
}

.ViewPurchaseOrder_orderDetails__3dHt4 {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.ViewPurchaseOrder_orderDetailsHeader__2DO6T {
  font-weight: bold;
  font-style: italic;
}

.ViewPurchaseOrder_orderDetailColumns__3R7Yt {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: #eaeaea;
  height: 32px;
}

.ViewPurchaseOrder_inputOptions__1BUgp {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
  margin-left: 15px;
}

.ViewPurchaseOrder_rowNumber__wlYYB {
  margin-right: 5px;
}

.ViewPurchaseOrder_materialUnit__2xjQt {
  width: 28% !important;
}

.ViewPurchaseOrder_inputContainer__1Y-oa {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.ViewPurchaseOrder_materials__1HxLe {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

.ViewPurchaseOrder_date__3fla_ {
  margin-left: 20px;
  margin-top: 6px;
}

.ViewPurchaseOrder_modifyButtons__1cqPt {
  position: absolute;
  width: 700px;
  left: 650px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
}

.ViewPurchaseOrder_modifyButton__3NUtT {
  width: 180px;
  margin-right: 10px !important;
  border: 2px solid #cecece !important;
}

.ViewPurchaseOrder_hidden__20iwH {
  display: none;
}

.ViewPurchaseOrder_autocompleteRoot__oLeDe {
  margin-right: 10px;
  width: 250px !important;
}

.ViewPurchaseOrder_autocomplete__28B9R {
  height: 10px !important;
}

.ViewPurchaseOrder_loading__biCHb {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.ViewPurchaseOrder_datePicker__3xvx6 {
  width: 150px !important;
  height: 50px !important;
}

.ConsolidatePreview_pageName__2XBHj {
  min-width: 200px;
  margin-left: 22px;
}

.ConsolidatePreview_header__33sxY {
  width: 1020px;
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.ConsolidatePreview_backButton__2yIoB {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.ConsolidatePreview_consolidateButton__5yRy9 {
  position: absolute;
  left: 36px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.ConsolidatePreview_approveBtn__1dwqa {
  width: 100px;
  height: 35px;
  background-color: #fc4059 !important;
  text-transform: capitalize;
  font-size: 12px !important;
  color: #fff !important;
}

.ConsolidatePreview_lightRed__2jnvh {
  background-color: #fc40595d !important;
  text-transform: capitalize;
}

.ConsolidatePreview_green__3wIdy {
  background-color: #69e4a746 !important;
  text-transform: capitalize;
}

.ConsolidatePreview_yellow__3KiTM {
  background-color: #ffc98360 !important;
  text-transform: capitalize;
}

.ConsolidatePreview_blue__2bpzN {
  background-color: #a7eaff60 !important;
  text-transform: capitalize;
}

.ConsolidatePreview_table__9MPRi {
  min-width: 1000px !important;
  min-height: 250px !important;
}

.ConsolidateProcess_consolidatePreviewTable__1FYYx {
  width: 100%;
}

.ConsolidateProcess_tableContainer__3zxiy {
  margin-top: 30px !important;
  min-width: 1100px;
  border: 1px solid black;
}

.ConsolidateProcess_head__2YCH2 {
  display: flex;
  justify-content: space-between;
  background: #ed731d;
  color: #fff;
  height: 30px;
}

.ConsolidateProcess_headText__2oZvy {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  font-weight: bold;
  font-style: italic;
}

.ConsolidateProcess_consolidatePreviewTable__1FYYx,
.ConsolidateProcess_consolidatePreviewTable__1FYYx th,
.ConsolidateProcess_consolidatePreviewTable__1FYYx td {
  min-width: auto;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.373);
  border-collapse: collapse;
}

.ConsolidateProcess_header__1Ru3E {
  display: flex;
  justify-content: space-between;
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
}

.ConsolidateProcess_backButton__nAI28 {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.ConsolidateProcess_sendEmailButton__pJjB8 {
  left: 855px;
  width: 150px;
  height: 40px;
  background-color: #1d68ed !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: bold;
}

.ConsolidateProcess_successDialog__Ng1yO {
  width: 500px;
}

.PredictionsRawMaterialTable_table__1meUV {
  min-width: 692px !important;
  min-height: 316px !important;
}

.PredictionsRawMaterialTable_container__3OuOy {
  margin-left: 150px;
}

.StoreForecast_pageName__3Ufk4 {
  min-width: 120px;
}

.AddApplicableMaterialModal_modal__2U7sV {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    opacity: 1;
    padding: 2px 4px 4px;
    margin-left: auto;
    margin-right: auto;
    width: 839px;
    height: 662px;
    overflow-y: scroll;
}

.AddApplicableMaterialModal_modalTitleBox__3hPsM {
    width: 839px;
    height: 110px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.AddApplicableMaterialModal_modalTitleText__AWYQA {
    text-align: center;
    font: bold 32px/40px Source Sans Pro;
    letter-spacing: 0px;
    color: #4d4d4d;
    opacity: 1;
    align-self: center;
    position: absolute;
}


.AddApplicableMaterialModal_modalCloseIcon__1Vdk5 {
    margin-left: auto;
    padding-right: 18px;
    padding-top: 23px;
    color: #4d4d4d;
}

.AddApplicableMaterialModal_modalBody__1dtmU {
    display: flex;
    flex-wrap: wrap;
    width: 733px;
    margin: 15px 53px;
}

.AddApplicableMaterialModal_favourite__80t_I {
    flex-direction: row;
    flex-grow: 1;
    width: 25%;
    margin-bottom: 27px;
}

.AddApplicableMaterialModal_favouriteName__1KB1h {
    font-size: 16px;
}
.PredictionsSemiPreparedMaterialTable_container__27rpu {
  width: 880px;
}

.PredictionsSemiPreparedMaterialTable_table__2BPwd {
  min-width: 800px !important;
  min-height: 316px !important;
}

.PredictionsSemiPreparedMaterialTable_header__3oKCX {
  border: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
}

.PredictionsSemiPreparedMaterialTable_widthDates__1vv0E {
  width: 94px !important;
  opacity: 0.8;
}

.PredictionsSemiPreparedMaterialTable_input__1PF4L {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.PredictionsSemiPreparedMaterialTable_submitButton__1Thjn {
  width: 60px;
  height: 23px;
}

.KitchenForecast_pageName__Iav5M {
  min-width: 200px;
}

.SalesForecast_pageName__1wuan {
  min-width: 120px;
}

.PredictionsPreparedMaterialTable_table__2jRH6 {
  min-width: 692px !important;
  min-height: 316px !important;
}

.PredictionsPreparedMaterialTable_container__19trp {
  margin-left: 150px;
}

