.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 500px;
}

.modalRoot {
  margin: 20px !important;
}

.modalTitleBox {
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalBody {
  opacity: 1;
  display: flex;
  flex-direction: column;
  width: 700px;
}

.eventDetails {
  display: flex;
  flex-direction: row;
  background-color: #f6f6f6;
}

.section {
  margin-top: 15px;
  margin-left: 30px;
  margin-right: 15px;
  width: 50%;
  display: flex;
  flex-direction: column;
}

.modalTitleText {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.modalCloseIcon {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.textFieldMargin {
  margin-bottom: 10px !important;
}

.userInput {
  margin-top: 15px;
  margin-bottom: 15px;
  margin-left: 30px;
  margin-right: 30px;
  display: flex;
  flex-direction: column;
}

.submitButton {
  width: 220px;
  align-self: center;
  margin-top: 30px !important;
}

.edit {
  padding-left: 23px;
  float: left;
}
