.nav {
  margin-bottom: 2px;
  overflow-y: scroll;
}

.nav::-webkit-scrollbar {
  display: none;
}

.root {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: 260px;
}

.list {
  padding: 0 !important;
}

.icon {
  margin-left: 5px;
  margin-right: 5px;
}

.button {
  width: 100%;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
}

.item {
  display: flex;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.title {
  padding-bottom: 6px;
  justify-content: flex-start;
  font-size: 13px;
  text-transform: none;
  letter-spacing: 0;
  width: 100%;
  font-weight: bold;
  text-align: left;
  height: 17px;
  opacity: 1;
}
