.container {
  margin-top: 20px;
  width: 1130px;
  height: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.scrollableListContainer {
  height: 600px;
  width: 1150px;
  display: flex;
  justify-content: space-between;
}

.modalRoot {
  overflow: scroll;
  border-color: white;
}

.addModalcontent {
  width: 512px;
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 50px;
}

.body {
  display: flex;
  flex-direction: row;
}

.modalBody {
  width: 350px;
  margin-bottom: 30px;
}

.addSubmitBtn {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
}

.addItemInput {
  border: 1px solid #cecece;
  margin-bottom: 42px;
  padding: 15px;
  font-size: 18px;
  width: 300px;
}

.addModalCloseIcon {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 450px;
}
