.body {
  width: auto;
  height: auto;
  display: flex;
  flex-direction: column;
}

.error {
  width: auto;
  height: auto;
  justify-content: left;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-top: 10px;
  border-block-start: 1.5px solid rgb(231, 230, 230);
}

.modalCloseIcon {
  color: #4d4d4d;
  margin-left: auto;
}

.iconButton {
  padding-bottom: 10px !important;
}

.errorText {
  font-size: 15px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-top: 22px;
  margin-left: 15px;
  margin-right: 15px;
}

.modalTitleText {
  text-align: center;
  font: bold 32px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  align-self: flex-start;
  margin-left: 15px;
  position: absolute;
  margin-top: 12px;
}

.addButton {
  width: 200px;
  height: 40px;
  background: #ed731d 0% 0% no-repeat padding-box;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #cecece;
  border-radius: 5px;
  opacity: 1;
  font: 16px Source Sans Pro;
  letter-spacing: 0px;
  color: #ffffff;
  margin-top: 20px;
}

.borders {
  border-bottom: 1.5px solid black;
}
