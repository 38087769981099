.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 400px;
}

.input {
  background-color: white;
  opacity: 0.7;
  margin: 0 auto;
  margin-top: 16px;
  margin-bottom: 12px;
  color: #4d4d4d !important;
}

.modalBody {
  min-width: 500px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.label {
  margin-top: 16px;
  margin-bottom: 8px;
  justify-content: center;
  align-items: center;
}
