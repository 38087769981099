.pageName {
  min-width: 200px;
  margin-left: 22px;
}

.header {
  width: 1020px;
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  display: flex;
  justify-content: space-between;
  margin-bottom: 35px;
}

.backButton {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.consolidateButton {
  position: absolute;
  left: 36px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.approveBtn {
  width: 100px;
  height: 35px;
  background-color: #fc4059 !important;
  text-transform: capitalize;
  font-size: 12px !important;
  color: #fff !important;
}

.lightRed {
  background-color: #fc40595d !important;
  text-transform: capitalize;
}

.green {
  background-color: #69e4a746 !important;
  text-transform: capitalize;
}

.yellow {
  background-color: #ffc98360 !important;
  text-transform: capitalize;
}

.blue {
  background-color: #a7eaff60 !important;
  text-transform: capitalize;
}

.table {
  min-width: 1000px !important;
  min-height: 250px !important;
}
