.root {
  display: flex;
  flex-direction: row;
}

.content {
  background-color: #f6f6f6;
  flex: 1;
  margin-left: 195px;
}

.header {
  background-color: #ffffff;
  height: 58px;
}

.date {
  float: left;
  padding-top: 18px;
  padding-left: 53px;
  color: #4d4d4d;
  font-size: 15px;
}

.welcome {
  float: right;
  padding-right: 70px;
  padding-top: 18px;
  color: #4d4d4d;
  font-weight: bold;
  font-size: 15px;
}
