.eventViewContainer {
  height: 580px;
  width: 550px;
}

.header {
  height: 80px;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.headerText {
  color: inherit;
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.modalCloseIcon {
  margin-left: auto;
}

.content {
  padding-top: 20px;
  display: flex;
}

.section {
  width: 50%;
  padding-left: 30px;
}

.divider {
  border-left: 1px solid #cecece;
  height: 460px;
}

.textFieldMargin {
  margin-bottom: 10px !important;
}

.invoice {
  height: 270px;
  width: 210px;
}

.invoice img {
  cursor: pointer;
  width: 100%;
  height: 100%;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(187, 187, 187, 0.19);
}
