.pageName {
  min-width: 200px;
  margin-left: 22px;
}

.header {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  display: flex;
}

.backButton {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.generateGrnButton {
  position: absolute;
  left: 580px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.downloadButton {
  position: absolute;
  left: 580px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #9b9b9b !important;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.details {
  width: 1100px;
  margin-top: 40px;
}

.content {
  width: 1100px;
  display: flex;
  justify-content: space-evenly;
  background-color: #fff;
  margin-bottom: 13px;
}

.card {
  padding: 10px;
  width: 33%;
  left: auto;
  text-align: left;
}

.inputCard {
  padding: 10px;
  width: 20%;
  left: auto;
  text-align: left;
}

.cardHeadText {
  padding-left: 25px;
  padding-top: 7px;
  display: flex;
}

.headDate {
  font-weight: bold;
}

.headSubHead {
  color: #4d4d4d;
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
}

.headSubText {
  margin-left: 20px;
  margin-top: 6px;
  font-weight: bold;
}

.detailsHeader {
  height: 32px;
  background-color: #eaeaea;
  color: #4d4d4d;
  display: flex;
}

.orderDetails {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.orderDetailsHeader {
  font-weight: bold;
  font-style: italic;
}

.orderDetailColumns {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: bold;
  background-color: #eaeaea;
  height: 32px;
}

.inputOptions {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 15px;
  margin-left: 15px;
}

.rowNumber {
  margin-right: 5px;
}

.materialUnit {
  width: 28% !important;
}

.inputContainer {
  width: 100%;
  display: flex;
  justify-content: space-evenly;
}

.materials {
  background-color: #fff;
  padding: 10px;
  margin-bottom: 20px;
}

.confirmationTitle {
  font-size: 24px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.confirmationBody {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.date {
  margin-left: 20px;
  margin-top: 6px;
}
