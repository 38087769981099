.modalTitleBox {
  min-width: 400px;
  height: 95px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalTitleBoxWithBranch {
  min-width: 400px;
  height: 110px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalTitleText {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
  width: 100%;
}

.modalCloseIcon {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
}

.modalButton {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border: 1px solid #cecece;
  border-radius: 5px;
  opacity: 1;
  font: Bold 21px/21px Source Sans Pro;
  letter-spacing: 0px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.date {
  padding-top: 23px;
  padding-left: 23px;
  float: left;
  border: 1px;
  z-index: 1;
}

.widthDates {
  width: 100px !important;
  opacity: 0.8;
}

.input {
  background-color: white;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 34px !important;
  font-weight: bolder;
}

.branchName {
  font-size: medium;
}

.download {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  width: 100px;
}

.downloadHidden {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  visibility: hidden;
}

.upload {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
}

.uploadHidden {
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  font-size: 15px !important;
  text-align: center;
  visibility: hidden;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 94%;
}
