.container {
  background-color: #ffffff;
  padding: 7px 18px;
  margin-top: 13px;
  margin-bottom: 26px;
}

.table {
  min-width: 892px !important;
  min-height: 316px !important;
}

.header {
  height: 34px;
  font-size: 15px;
  font-weight: bold !important;
  background-color: #f9f9f9;
  padding-top: 8px;
  padding-bottom: 8px;
  line-height: 34px;
}

.group {
  border: 1px;
}

.cell {
  padding-left: 12px !important;
  padding-right: 12px !important;
}

.center {
  text-align: center !important;
}

.fromTo {
  float: left;
  border: 1px;
}

.widthDates {
  width: 94px !important;
  opacity: 0.8;
}

.input {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.mainDate {
  background-color: white;
  font-size: 12px;
  text-align: center;
}

.searchIcon {
  width: 12px;
  height: 12px;
}

.loadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pink {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.green {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.lastrow {
  border-bottom: 2px solid #a1a1a1;
}

.list {
  overflow: visible !important;
  height: unset !important;
}

.table {
  border-collapse: collapse !important;
}

.headerBox {
  display: inline-flex;
}

.leftAlign {
  text-align: left;
}

.pendingButton {
  float: right;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
  height: 24px;
  background-color: #4d4d4d !important;
  color: white !important;
}
