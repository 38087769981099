.approval {
  width: 512px;
  height: 450px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.body {
  display: flex;
  flex-direction: row;
}

.approvalButton {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 21px !important;
  margin-bottom: 25px !important;
}

.line {
  width: 90%;
  text-align: center;
  border-bottom: 1px solid #cecece;
  line-height: 0.1em;
  margin: 10px 0 20px;
}

.line span {
  color: #cecece;
  background: #fff;
  padding: 0 10px;
}

.commentButton {
  width: 217px;
  height: 58px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  text-align: center;
  font-size: 21px !important;
}

.comment {
  width: 430px;
  margin-bottom: 20px;
}

.approvalText {
  font-size: 28px;
  text-align: center;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 42px;
}

.modalCloseIcon {
  margin-left: 10px;
  margin-bottom: 10px;
  color: #4d4d4d;
  float: right;
  position: absolute;
  left: 450px;
}
