.form {
  width: 1126px;
  margin-top: 27px;
  height: auto;
}

.header {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.headText {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.inputOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  margin: 15px;
  margin-left: 31px;
}

.rowNumber {
  margin-right: 10px;
}

.autocompleteRoot {
  margin-right: 10px;
  width: 500px !important;
  height: 50px;
}

.autocomplete {
  width: 179px !important;
  color: black !important;
}

.addItem {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-right: 20px;
  float: left;
}

.addSupplierButton {
  position: absolute;
  left: 762px;
  bottom: 60px;
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 16px !important;
}

.addItemDiv {
  display: flex;
  align-items: center;
  margin-left: 55px;
  margin-right: 55px;
}
