.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 500px;
}

.materialUnit {
  margin-top: 8px !important;
  min-width: 150px !important;
}

.input {
  background-color: white;
  width: 209px !important;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 25px;
}

.quantity {
  width: 300px;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rowNumber {
  margin-right: 10px;
}

.radioSelection {
  align-self: flex-start;
  margin-left: 10px !important;
  margin-top: 10px !important;
  margin-bottom: 10px !important;
}

.inputOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 8px;
}

.icon {
  right: 10% !important;
}

.autocompleteRoot {
  margin-right: 25px;
}

.autocomplete {
  width: 150px !important;
}

.addMore {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
}

.confirmationTitle {
  font-size: 24px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.confirmationBody {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}

.materialItem {
  width: 100%;
}

.materialsListContainer {
  display: flex;
  flex-wrap: wrap;
}

.materialsListContainer>.materialItem {
  flex: 25%;
  margin-bottom: 3px;
}
