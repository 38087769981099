.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 1150px;
  min-height: 500px;
}

.modalBody {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 20px;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.divider {
  border-left: 1px solid #cecece;
  margin-left: 30px;
  margin-right: 30px;
  height: 400px;
}

.input {
  margin: 8px !important;
  width: 300px !important;
}

.datesWidth {
  width: 300px !important;
}

.defaultMargin {
  margin: 8px !important;
}

.delete {
  padding-left: 23px !important;
  float: left;
}

.title {
  padding-right: 150px !important;
}

.confirmationTitle {
  font-size: 28px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 15px;
}

.confirmationBody {
  text-align: center;
  margin-left: 30px;
  margin-right: 30px;
}
