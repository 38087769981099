.fromTo {
  float: left;
  border: 1px;
}

.widthDates {
  width: 94px !important;
  opacity: 0.8;
}

.input {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 7px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.mainDate {
  background-color: white;
  font-size: 12px;
  text-align: center;
}

.clearButton {
  margin-top: 10px !important;
  display: inline-block;
  font-size: 14px;
}
