.container {
  background-color: #ffffff;
  padding: 20px 18px;
}

.header {
  min-height: 51px;
  font-size: 15px;
  font-weight: bold !important;
  background-color: #f9f9f9;
}

.cell {
  padding: 0px !important;
}

.loadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.centerAlign {
  text-align: center !important;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}