.table {
  min-width: 892px !important;
  min-height: 316px !important;
}

.red {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.green {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.yellow {
  background-color: #ffca83 !important;
  text-transform: capitalize;
}
