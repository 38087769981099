.pageName {
  min-width: 200px;
  margin-left: 22px;
}

.header {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.backButton {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.loadingDiv {
  display: flex;
  align-items: center;
  justify-content: center;
}

.loading {
  display: flex;
  align-items: center;
  justify-content: center;
}
