.root {
  height: 100vh;
  padding: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loginDiv {
  width: 507px;
  min-height: 387px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0px 3px 17px #2c28281c;
  opacity: 1;
}

.validate {
  width: 218px;
  height: 58px;
  font-weight: bold !important;
  font-size: 20px !important;
  text-transform: capitalize !important;
  margin-top: 26px !important;
}

.logo {
  margin-top: 10px;
}

.mainHeader {
  color: #4d4d4d;
  letter-spacing: 0px;
  font-weight: bold;
  font-size: 24px;
  margin-bottom: 9px;
}

.react-phone-number-input__country {
  display: none;
}

.subHeader {
  color: #4d4d4d;
  font-size: 15px;
  margin-bottom: 28px;
}

.form {
  margin-top: 10px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
