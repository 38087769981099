.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 800px;
  min-height: 662px;
}

.modalRoot {
  overflow: scroll;
  border-color: white;
}

.modalTitleBox {
  height: 140px;
  min-width: 100%;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalTitleText {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.modalNav {
  width: 90%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.modalCloseIcon {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 13px;
}

.backToEntriesBtn {
  margin-right: auto;
  padding-left: 18px;
  padding-top: 23px;
}

.toGraphBtn {
  float: right;
  padding: 5px 30px 10px 0;
}

.text {
  font-size: medium;
}

.date {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.branch {
  font-size: small;
}

.quantityText {
  width: 50px;
  text-align: center;
}
.icon {
  cursor: pointer;
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.submitButton {
  width: 150px;
  height: 30px;
}
