.header {
  border: 1px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  margin-top: 15px;
}

.widthDates {
  width: 94px !important;
  opacity: 0.8;
}

.input {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.submitButton {
  width: 60px;
  height: 23px;
}

.container {
  width: 780px;
  text-align: left;
}

.autocompleteRoot {
  margin: 0 0 20px 0;
  width: 365px !important;
  height: 50px;
}

.autocomplete {
  width: 179px !important;
}
