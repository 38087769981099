.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 1120px;
  min-height: 662px;
}

.modalRoot {
  overflow: scroll;
  border-color: white;
}

.modalTitleBox {
  width: 1120px;
  height: 140px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalTitleText {
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.modalCloseIcon {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
}

.quantity {
  font-size: medium;
}

.date {
  font: 32px/40px Source Sans Pro;
  font-size: small;
  text-align: left;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 10px;
  margin-left: 10px;
}

.branch {
  font-size: small;
}
