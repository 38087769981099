.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 591px;
  min-height: 662px;
}

.modalRoot {
  overflow: scroll;
  border-color: white;
}

.modalTitleBox {
  width: 591px;
  height: 95px;
  opacity: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  position: relative;
}

.modalTitleText {
  margin-top: 15px;
  text-align: center;
  font: bold 32px/40px Source Sans Pro;
  letter-spacing: 0px;
  opacity: 1;
  align-self: center;
  position: absolute;
}

.edit {
  margin-top: 30px;
  margin-left: 30px;
}

.modalCloseIcon {
  margin-left: auto;
  padding-right: 18px;
  padding-top: 23px;
  color: white;
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.levelZeroText {
  margin: 11px 10px 11px 20px;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 0px;
}

.levelZeroDetail {
  background-color: #fce3d1;
  min-width: 561px;
  height: 47px;
  margin-top: 20px;
  margin-right: 14px;
  margin-left: 14px;
}

.levelTwoOrGreater {
  font-weight: 500;
}

.right {
  display: flex;
}

.index {
  margin-right: 7px;
}

.detail {
  display: flex;
  padding: 0;
  margin-left: 14px;
  min-width: 561px;
}

.line {
  border-bottom: 1px dashed #4d4d4d;
  height: 0px;
  margin-top: 20px;
}

.grow {
  flex-grow: 1;
}

.innerDiv {
  display: flex;
}

.toggleButton {
  width: 85px;
}

.quantity {
  font-size: medium;
}
