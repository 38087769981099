.consolidatePreviewTable {
  width: 100%;
}

.tableContainer {
  margin-top: 30px !important;
  min-width: 1100px;
  border: 1px solid black;
}

.head {
  display: flex;
  justify-content: space-between;
  background: #ed731d;
  color: #fff;
  height: 30px;
}

.headText {
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 5px;
  font-weight: bold;
  font-style: italic;
}

.consolidatePreviewTable,
.consolidatePreviewTable th,
.consolidatePreviewTable td {
  min-width: auto;
  height: 40px;
  border: 1px solid rgba(0, 0, 0, 0.373);
  border-collapse: collapse;
}

.header {
  display: flex;
  justify-content: space-between;
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
}

.backButton {
  height: 35px;
  width: 100px;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  padding: 0 30px !important;
}

.sendEmailButton {
  left: 855px;
  width: 150px;
  height: 40px;
  background-color: #1d68ed !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: bold;
}

.successDialog {
  width: 500px;
}
