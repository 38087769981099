.root {
  background-color: #4d4d4d;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select {
  margin: 0 auto;
  right: 13%;
}
