.commentParent {
  position: relative;
}

.comment {
  margin: 8px;
}

.commentDiv {
  position: absolute;
  padding: 12px;
  width: 235px;
  min-height: 90px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  left: 120px;
  top: -5px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px #0000003a;
  margin-bottom: 5px;
  line-height: 20px;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
}

.modalCloseIcon {
  color: #4d4d4d;
  margin-left: auto;
}
.date {
  font-size: 12px;
  text-align: left;
}

.iconButton {
  padding: 0px !important;
  top: -15px;
  right: -5px;
}

.commentText {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
  text-align: left;
}

.miniBody {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.commentBox {
  margin-top: 3px;
  width: 215px;
}

.divider {
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.dividerButton {
  height: 2px !important;
  background-color: #000000 !important;
  margin-top: 12px !important;
  margin-bottom: 12px !important;
}

.commentButton {
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  margin-top: 12px !important;
  color: #4d4d4d !important;
  background-color: #f6f6f6 !important;
  text-align: center;
}

.approveButton {
  margin-top: 12px !important;
  display: flex;
  justify-content: center;
  align-self: center;
  width: 150px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  text-align: center;
}
