.modal {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 6px #0000000a;
    opacity: 1;
    padding: 2px 4px 4px;
    margin-left: auto;
    margin-right: auto;
    width: 839px;
    height: 662px;
    overflow-y: scroll;
}

.modalTitleBox {
    width: 839px;
    height: 110px;
    background: #f6f6f6 0% 0% no-repeat padding-box;
    opacity: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.modalTitleText {
    text-align: center;
    font: bold 32px/40px Source Sans Pro;
    letter-spacing: 0px;
    color: #4d4d4d;
    opacity: 1;
    align-self: center;
    position: absolute;
}


.modalCloseIcon {
    margin-left: auto;
    padding-right: 18px;
    padding-top: 23px;
    color: #4d4d4d;
}

.modalBody {
    display: flex;
    flex-wrap: wrap;
    width: 733px;
    margin: 15px 53px;
}

.favourite {
    flex-direction: row;
    flex-grow: 1;
    width: 25%;
    margin-bottom: 27px;
}

.favouriteName {
    font-size: 16px;
}