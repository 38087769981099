.scrollableList {
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.header {
  background-color: #eaeaea;
  height: 54px;
  width: 350px;
  position: relative;
  color: #4d4d4d;
  font: normal bold 16px/30px "Source Sans Pro";
}

.headerText {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.addIcon {
  position: absolute;
  float: right;
  right: 20px;
  bottom: 20px;
}

.list {
  overflow-y: scroll;
  width: 350px;
  flex-grow: 1;
}

.listItem {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 50px;
  height: auto;
  width: 280px;
  border-bottom: 1px solid #9b9b9b29;
  padding: 0 20px 0 20px;
}

.listItemText {
  padding: 5px 0 5px 0;
  inline-size: 200px;
  font: normal bold 15px/30px "Source Sans Pro";
  overflow-wrap: break-word;
  text-align: left;
}

.deleteIcon,
.addEntry {
  cursor: pointer;
}
