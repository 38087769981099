.pageName {
  min-width: 200px;
  margin-left: 22px;
}

.backButton {
  padding: 0 30px !important;
  border: 1px solid #9b9b9b !important;
  border-radius: 3px !important;
  color: black !important;
  height: 35px;
  width: 100px;
}
