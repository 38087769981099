.header {
  height: 27px;
  text-align: left;
  font-size: 21px;
  font-style: italic;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  margin-bottom: 1px;
  display: flex;
}

.container {
  margin-left: 53px;
  margin-top: 24px;
  display: flex;
}

.rightSection {
  margin-left: 40px;
  margin-top: 13px;
}
