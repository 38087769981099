.modal {
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  padding: 2px 4px 4px;
  margin-left: auto;
  margin-right: auto;
  width: 700px;
  min-height: 662px;
}

.input {
  background-color: white;
  width: 209px !important;
  font-size: 14px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  opacity: 0.5;
  margin: 0 auto;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-right: 15px;
  color: #4d4d4d !important;
}

.modalBody {
  min-height: 423px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.inputOptions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 15px;
}

.icon {
  right: 10% !important;
}

.autocompleteRoot {
  margin-right: 10px;
}

.autocomplete {
  width: 179px !important;
}

.rowNumber {
  margin-right: 10px;
}

.addMore {
  width: 195px;
  height: 38px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #cecece;
  border-radius: 30px;
  opacity: 1;
  margin-top: 20px;
  margin-bottom: 10px;
  left: 35%;
  position: relative;
}

.noDisplay {
  display: none;
}

.materialUnit {
  width: 250px;
  margin-left: 10px !important;
}
