.table {
  min-width: 892px !important;
  min-height: 316px !important;
}

.pink {
  background-color: #fbd6db !important;
  text-transform: capitalize;
}

.green {
  background-color: #98efc3 !important;
  text-transform: capitalize;
}

.noCenter {
  padding-left: 20px;
  text-align: left;
}
