.pageName {
  min-width: 200px;
}

.date {
  margin-left: 750px;
  font-size: 12px;
  font-style: italic;
  font-weight: bold;
  color: #4d4d4d;
}
