.body {
  width: 400px;
  height: auto;
  display: flex;
  flex-direction: column;
}

.modalCloseIcon {
  color: #4d4d4d;
  margin-left: auto;
}

.iconButton {
  padding-bottom: 10px !important;
}

.modalTitleText {
  text-align: center;
  font: bold 26px Source Sans Pro;
  letter-spacing: 0px;
  color: #4d4d4d;
  opacity: 1;
  align-self: flex-start;
  margin-left: 15px;
  position: absolute;
  margin-top: 12px;
}

.button {
  width: 150px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  font: Bold 21px/21px Source Sans Pro !important;
  font-size: 15px !important;
  text-align: center;
  align-self: center;
  margin: 10px !important;
}

.borders {
  border-bottom: 1.5px solid black;
}
