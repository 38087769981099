.datepicker {
  float: right;
}

.headerBranch {
  float: left;
  color: #4d4d4d;
  font-size: 14px;
  font-weight: 500;
}

.headerDate {
  font-weight: bold;
}

.headerTitle {
  font-weight: 500;
}

.headerCentral {
  margin-left: 20%;
  flex-grow: 1;
  color: #4d4d4d;
  font-size: 13px;
}
