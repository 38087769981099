.input {
  background-color: white;
  font-size: 12px !important;
  border: 1px solid #cecece;
  border-radius: 4px;
  margin: 4px !important;
  color: #000000 !important;
  height: 24px !important;
  font-weight: bolder;
}

.inline {
  display: inline;
}

.searchRoot {
  float: right;
  opacity: 0.8;
  margin-right: 8px !important;
  width: 132px !important;
}

.searchRoot ::placeholder {
  color: #4d4d4d !important;
  font-weight: bolder;
  opacity: 0.8;
}

.widthSearch {
  width: 132px !important;
}
