.form {
  width: 1126px;
  margin-top: 27px;
}

.header {
  height: 32px;
  background-color: #4d4d4d;
  color: #ffffff;
  display: flex;
}

.headText {
  margin-left: 20px;
  margin-top: 6px;
  font-style: italic;
  font-weight: bold;
}

.formBody {
  height: auto;
  background: #ffffff;
  padding: 25px;
}

.textBox {
  width: 340px;
  margin-top: 5px !important;
}

.footer {
  height: 80px;
  padding-left: 25px;
  padding-right: 25px;
  background: #eaeaea;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.excelButtons {
  display: flex;
  justify-content: space-between;
}

.excelButton {
  width: 200px;
  height: 50px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  color: #4d4d4d !important;
  background-color: #ffffff !important;
  font-size: 15px !important;
  text-align: center;
}

.excelIcon {
  height: 27px;
  width: 27px;
}

.selectItemsButton {
  width: 200px;
  height: 50px;
  border: 2px solid #ed731d !important;
  color: #ed731d !important;
  font-weight: "bold" !important;
  background: #ffffff 0% 0% no-repeat padding-box !important;
  box-shadow: 0px 3px 6px #2c28281c !important;
  border-radius: 5px !important;
  opacity: 1 !important;
}

.inputDiv {
  width: 465px;
  text-align: left;
}

.inputLabel {
  margin-top: 16px;
  font-weight: bold;
}

.autocompleteRoot {
  margin-right: 10px;
  width: 340px !important;
  height: 50px;
}

.autocomplete {
  width: 179px !important;
}

.datePicker {
  width: 340px !important;
}
