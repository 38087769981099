.commentParent {
  position: relative;
}

.commentDiv {
  position: absolute;
  padding: 10px;
  width: 190px;
  height: 90px;
  display: flex;
  flex-direction: column;
  z-index: 999;
  left: 120px;
  top: -5px;
  background-color: #ffffff;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px #0000003a;
  text-align: left !important;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
}

.employeeName {
  font-size: 16px;
  font-weight: bold;
}

.modalCloseIcon {
  color: #4d4d4d;
  margin-left: auto;
}
.date {
  font-size: 12px;
}

.iconButton {
  padding: 0px !important;
}

.commentText {
  margin-top: 10px;
  font-size: 15px;
  font-weight: 500;
}

.miniBody {
  display: flex;
  flex-direction: row;
}
