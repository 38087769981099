.root {
  height: 100%;
  position: fixed;
  z-index: 1;
  display: flex;
  flex-direction: column;
  padding: 0px;
  width: 195px;
  flex: 1 1;
}

.img {
  width: 150px;
  height: 30px;
}

.header {
  padding: 12px;
}
