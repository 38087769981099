.pageName {
  min-width: 200px;
}

.button {
  width: 190px;
  height: 60px;
  border: 0px;
  color: #4d4d4d;
  font-size: 12px;
  font-weight: 400;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 2px 6px #0000000a;
  opacity: 1;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 6px;
  margin-right: 15px;
}

.button:hover {
  background-color: #fafafa;
  font-weight: 600;
}

.buttonTitle {
  margin-left: 9px;
  text-align: left;
}

.icon {
  height: 28px !important;
  width: 28px !important;
  margin-left: 10px;
}

.genConsolidatedPoBtn {
  left: 140px;
  width: 210px;
  height: 40px;
  background-color: #1d68ed !important;
  text-transform: capitalize;
  font-size: 16px !important;
  font-weight: bold;
}

.autocompleteRoot {
  margin: 0 0 30px 0;
  width: 365px !important;
  height: 50px;
}

.autocomplete {
  width: 179px !important;
}
