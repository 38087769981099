.table {
  min-width: 892px !important;
  min-height: 250px !important;
}

.addSubmitBtn {
  width: 100px;
  height: 35px;
  box-shadow: 0px 3px 6px #2c28281c;
  border-radius: 5px;
  opacity: 1;
  text-align: center;
  font-size: 12px !important;
}